import React from 'react';
//import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PasswordResetPage = () => (
  <Layout>
    <SEO title="Email Verification" />
    
    <p>Please check your email for a verfication link.</p>
    <p>Please follow the link to verify your email address before you can login.</p>
  </Layout>
);

export default PasswordResetPage;
